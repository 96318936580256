import { withApollo } from "next-apollo";
import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwt from 'jsonwebtoken'
import typeDefs from './schema'

export const environment = 'PRODUCTION'
export const testPeriod = false

const getUri = () => {
  if (environment === 'LOCAL') {
    return typeof window === 'undefined' ? 'http://host.docker.internal:3002/graphql' : 'http://localhost:3002/graphql'
  } else if (environment === 'STAGING') {
    return typeof window === 'undefined' ? 'http://gateway:3002/graphql' : 'https://perpetual.lizard.global/graphql'
  } else {
    return typeof window === 'undefined' ? 'http://gateway:3002/graphql' : 'https://crm.perpetual.com.my/graphql'
  }
}

const httpLink = createHttpLink({
  uri: getUri()
});

const noAuthQueries = [
  'RefreshAccessToken', 
  'Login', 
  'AddUser',
  'GetLead',
  'GetUserById',
  'CreatePayment'
]

const authLink = setContext(async (request, { headers }) => {
  if (noAuthQueries.includes(request.operationName)) { 
    // Don't need access token for these queries
    return {
      headers: {
        ...headers,
      }
    }
  }

  let access_token, refresh_token;
  if (typeof localStorage !== 'undefined') {
    access_token = localStorage.getItem('access_token');
    refresh_token = localStorage.getItem('refresh_token');
  }

  const token_decoded = jwt.decode(access_token)
  const current_timestamp = Date.now().valueOf() / 1000
  
  if (refresh_token && (!token_decoded || current_timestamp > token_decoded.exp)) { 
    //Token has expired, request a new one 
    try {
      let { data, error: refreshError } = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: gql`
          query RefreshAccessToken($refresh_token: String!) {
            refreshAccessToken(refresh_token: $refresh_token)
          }
        `,
        variables: { refresh_token }
      })
      access_token = data.refreshAccessToken
      if (access_token && typeof localStorage !== 'undefined' && !refreshError) {
        localStorage.setItem("access_token", access_token)
      }
    } catch (err) {
      console.log('issue with refresh token', err)
      //Refresh token invalid
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
      }
      window.location.href = '/signin'
    }
  }


  return {
    headers: {
      ...headers,
      authorization: access_token ? access_token : "",
    }
  }
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
  typeDefs
});

export default withApollo(apolloClient);
