import { gql } from '@apollo/client';

const typeDefs = gql`
  type User {
    name: String
    company: String
    phone: String
    email: String
    role: String
    status: String
    createdAt: Float
    uid: ID
    registeredAt: Float
    id: ID
    access_token: String
    refresh_token: String
    isSuspended: Boolean
    bank: String
    account_holder: String
    account_number: String
    sharedLink: Float
    openedLink: Float
    stripeStatus: String
    stripeAccountId: String
    seenWelcomeModal: Boolean
    payoutMode: String
    seenAdvanced: Boolean
    dontShowTutorial: Boolean
  }

  input UserInput {
    name: String
    company: String
    phone: String
    email: String
    role: String
    status: String
    createdAt: Float
    uid: String
    registeredAt: Float
    password: String
    id: ID
    bank: String
    account_holder: String
    account_number: String
    isReminder: Boolean
    ip: String
    userAgent: String
    stripeStatus: String
    seenWelcomeModal: Boolean
    payoutMode: String
    mode: String
    dontShowTutorial: Boolean
    seenAdvanced: Boolean
  }


  # Leads

  type Lead {
    name: String
    phone: String
    email: String
    status: status
    createdAt: Float
    updatedAt: Float
    agent: String
    notes: [note]
    products: [product]
    id: String
    reseller: String
    value: Float
    isAwaitingPayment: Boolean
    orderNumber: Float
    agentId: String
    archived: Boolean
  }

  input leadInput {
    isActive: Boolean
    name: String
    phone: String
    email: String
    status: status
    createdAt: Float
    updatedAt: Float
    agent: String
    reseller: String
    notes: [noteInput]
    products: [productInput]
    id: String
    profit: Float
    isAwaitingPayment: Boolean
    orderNumber: Float
  }

  type note {
    createdAt: Float
    updatedAt: Float
    title: String
    description: String
  }

  type product {
    agent: ID
    agentName: String
    name: String
    retailPrice: Float
    profit: Float
    price: Float
    imageUrl: String
    category: String
    availability: String
    description: String
    quantity: Float
    retail_fullpayment: Float
    profit_amount_fullpayment: Float
    profit_percent_fullpayment: Float
    commission_amount_agent_fullpayment: Float
    commission_percent_agent_fullpayment: Float
    commission_amount_reseller_fullpayment: Float
    commission_percent_reseller_fullpayment: Float
    has_instalments: Boolean
    retail_instalment: Float
    profit_amount_instalment: Float
    profit_percent_instalment: Float
    commission_amount_agent_instalment: Float
    commission_percent_agent_instalment: Float
    commission_amount_reseller_instalment: Float
    commission_percent_reseller_instalment: Float
    downpayment: Float
    id: ID
    productId: ID
    promotion_rate: Float
    promotion_img: [String]
    template: String
  }

  input noteInput {
    createdAt: Float
    updatedAt: Float
    title: String
    description: String
  }

  input productInput {
    name: String
    agent: ID
    retailPrice: Float
    profit: Float
    price: Float
    imageUrl: String
    category: String
    availability: String
    description: String
    quantity: Float
    retail_fullpayment: Float
    profit_amount_fullpayment: Float
    profit_percent_fullpayment: Float
    commission_amount_agent_fullpayment: Float
    commission_amount_reseller_fullpayment: Float
    commission_percent_reseller_fullpayment: Float
    has_instalments: Boolean
    retail_instalment: Float
    profit_amount_instalment: Float
    profit_percent_instalment: Float
    commission_amount_agent_instalment: Float
    commission_percent_agent_instalment: Float
    commission_amount_reseller_instalment: Float
    commission_percent_reseller_instalment: Float
    downpayment: Float
    productId: ID
    promotion_rate: Float
    template: String
  }

  enum status {
    Open
    Closed
    Lost
    Payment pending
  }

  enum mode {
    MyProducts
    Marketplace
    All
  }

  type Reseller {
    id: ID
    name: String!
    phone: String!
    email: String!
    status: Status!
    opportunity_count: Opportunities
    opportunity_list: [ID]
    total_opportunity: Float
    createdAt: Float
    registeredAt: Float
    uid: ID
  }

    input ResellerInput {
    name: String!
    phone: String!
    email: String!
    status: Status
    agent: String!
    opportunuties: [ID]
  }


  type Opportunities {
    open: Float
    lost: Float
    closed: Float
  }

  enum Status {
    Active
    Inactive
    Pending
    Retired
  }

  input paymentInput {
    opportunityId: ID!
    instalmentPeriod: Float!
    url: String!
    createdAt: Float
    updatedAt: Float
    customer: customerInput!
    status: status
    type: String
    events: [ eventInput ]
    commissionaireId: ID
    paymentMethod: paymentMethod
    isAutoDeduction: Boolean
  }

  enum paymentMethod {
    card
    fpx
    cash
  }

  input eventInput {
    createdAt: Float
    status: status
  }

  input customerInput {
    name: String!
    email: String!
    phone: String!
  }

  enum paymentStatus {
    Paid
    Due
    Late
    Cancelled
    Paused
  }


  type payment {
    opportunityId: ID
    instalmentPeriod: Float
    createdAt: Float
    updatedAt: Float
    customerName: String
    customerId: String
    status: paymentStatus
    events: [ event ]
    value: Float
    id: ID
    instalmentAmount: Float
    subscriptionId: String
    paidAmount: Float
    commissionaireId: ID
    agentId: ID
    commission_percent: Float
    pausedPaymentIndexes: [Float]
    currentInstalment: Float
    subscriptionConfig: String
  }

  enum paymentStatus {
    Cleared
    Due
    Late
    Cancelled
  }

  type event {
    createdAt: Float
    status: paymentStatus
    invoiceId: String
    paidAt: Float
    amount: Float
  }

  type incomeData {
    from: Float
    to: Float
    text: String
    Revenue: Float
    Commission: Float
    forecasted: Boolean
  }

  input interval {
    from: Float
    to: Float
    text: String
  }

  input promotionInput {
    promotionId: String
    description: String
    rate: Float
    from: Float
    to: Float
    image: String
    status: String
    products: [ID]
  }

  type promotion {
    promotionId: String
    description: String
    rate: Float
    from: Float
    to: Float
    image: String
    status: String
    products: [ID]
    id: ID
  }

  type Customer {
    details: [CustomerDetails]
    plot: [Plot]
    family: [CustomerDetails]
    contact: [CustomerDetails]
    documents: Documents
    id: ID
    importedAt: Float
    updatedAt: Float
    productId: String
  }

  type CustomerDetails {
    zone: String
    row: String
    lot: String
    purchaserId: String
    clientId: String
    orderNr: String
    engName: String
    chiName: String
    nric: String
    dob: String
    gender: String
    address1: String
    address2: String
    address3: String
    city: String
    state: String
    postcode: String
    country: String
    mobile: String
    phone: String
    relationship: String
    related_to: String
    email: String
    birthrank: String
    deceased: String
  }

  type Plot {
    product: String
    type: String
    description: String
    area: String
    zone: String
    row: String
    lot: String
    engName: String
    chiName: String
    date: String
    deathCert: String
    religion: String
    dialect: String
    placing: String
    location: String
    gender: String
    birth_date: String
    death_date: String
    landId: String
    urnId: String
    relationship: String
  }

  input FileInput {
    name: String
    url: String
  }

  type File {
    name: String
    url: String
  }

  type Documents {
    deathCert: [String]
    receipt: String
    spa: String
    burialApplication: [File]
    burialPermit: [File]
    tombTablet: File
    front: File
    wide: File
    side: File
    oth1: File
    oth2: File
    oth3: File
    oth4: File
    others: [File]
  }


  input CustomerInput {
    details: [CustomerDetails]
    plot: [Plot]
    family: [CustomerDetails]
    contact: [CustomerDetails]
    documents: Documents
    importedAt: Float
    updatedAt: Float
    productId: String
  }

  input CustomerDetailsInput {
    zone: String
    row: String
    lot: String
    purchaserId: String
    clientId: String
    orderNr: String
    engName: String
    chiName: String
    nric: String
    dob: String
    gender: String
    address1: String
    address2: String
    address3: String
    city: String
    state: String
    postcode: String
    country: String
    mobile: String
    phone: String
    relationship: String
    related_to: String
    email: String
    birthrank: String
    deceased: String
  }

  input PlotInput {
    product: String
    type: String
    description: String
    area: String
    zone: String
    row: String
    lot: String
    engName: String
    chiName: String
    date: String
    deathCert: String
    religion: String
    dialect: String
    placing: String
    location: String
    gender: String
    birth_date: String
    death_date: String
    landId: String
    urnId: String
    relationship: String
  }

  input DocumentsInput {
    deathCert: [String]
    receipt: String
    spa: String
    burialApplication: [FileInput]
    burialPermit: [FileInput]
    tombTablet: FileInput
    front: FileInput
    wide: FileInput
    side: FileInput
    oth1: FileInput
    oth2: FileInput
    oth3: FileInput
    oth4: FileInput
    others: [FileInput]
  }



  
`
export default typeDefs;